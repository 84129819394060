import { navigate } from "@/Hooks/Functions";
import { state } from "@state/store";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { AiOutlineHighlight } from "react-icons/ai";
import { useSnapshot } from "valtio";
import StartDesignModal from "../components/StartDesignModal";

const Overlay = () => {
    const snap = useSnapshot(state);
    const [show, setShow] = useState(false);

    const transition = { type: "spring", duration: 0.8 };
    const config = {
        initial: {
            x: -100,
            opacity: 0,
            transition: { ...transition, delay: 0.5 },
        },
        animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0 } },
        exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } },
    };
    const onSelect = (product) => {
        setShow(false);
        navigate(route("designer.via-design-product", { slug: product.slug }), 'GET');
    };
    return (
        <div className="absolute w-full md:h-[calc(100vh-70px)] h-screen top-0 left-0 ">
            <AnimatePresence>
                <motion.section key="main" {...config}>
                    <div className="section--container relative flex justify-end items-stretch md:!pt-[70px] pt-0">
                        <motion.div
                            key="title" className="md:visible invisible"
                            initial={{ x: 100, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{
                                type: "spring",
                                damping: 5,
                                stiffness: 40,
                                restDelta: 0.001,
                                duration: 0.3,
                            }}
                        >
                            <h1>Rock My Prompt.</h1>
                        </motion.div>
                        <div className="md:w-1/3 w-[80vw] mx-auto rounded-2xl border border-primary bg-primary/60 p-4  md:right-0 top-[55%] absolute ">
                            <motion.div
                                className="flex-col flex text-white text-center justify-center items-center gap-4"
                                key="p"
                                initial={{ y: 100, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{
                                    type: "spring",
                                    damping: 7,
                                    stiffness: 30,
                                    restDelta: 0.001,
                                    duration: 0.6,
                                    delay: 0.2,
                                    delayChildren: 0.2,
                                }}
                            >
                                <p data-aos="fade-in">Create your unique and exclusive shirt with our brand-new AI customization tool. Unleash your imagination and define your own style.</p>
                                <button
                                    className="goToEditor"
                                    style={{ background: snap.color }}
                                    onClick={() => setShow(true)}
                                >
                                    Start Designing
                                    <AiOutlineHighlight size="1.3em" />
                                </button>
                            </motion.div>
                        </div>
                    </div>
                </motion.section>
            </AnimatePresence>

            <StartDesignModal show={show} onClose={() => setShow(false)} onSelect={onSelect} />
        </div>
    );
};

export default Overlay;
